import React, {Component} from 'react';
import {connect} from "react-redux";
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import {requiredValidator} from '../../components/validator';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class Database_new extends Component{
    constructor(props) {
        super(props);

        this.state = {
            database_name: '',
            prefix_name: ''
        };
    }

    componentDidMount() {
        //this.props.getProductsList();
    }

    handleSubmit = (dataItem) => {
         this.props.getUserCredential();
    };

    getProductNumberValue = (i) => {
        let val = this.state.productNumbers.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    getProductDescriptionValue = (i) => {
        let val = this.state.productDescriptions.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    getProductValue = (i) => {
        let val = this.state.autoCompleteValue.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };


    render() {
        //const { allProducts, loading } = this.props.priceApp;

        return (
            <div className="k-rtl App" style={{ marginTop: '10px'}}>
                <h3 style={{paddingRight: 40}}>مشخصات دیتابیس</h3>
                <div className="k-flex-row formRow">
                    <Label editorId='crm_database' className='formLabel'>
                      نام دیتابیس  :
                    </Label>
                    <Input
                        id='crm_database'
                        value={this.state.database_name}
                        onChange={(e) => this.setState({database_name: e.value})}
                    />
                    <Label editorId='crm_database_prefix' className='formLabel'>
                        پرفیکس  :
                    </Label>
                    <Input
                        id='crm_database_prefix'
                        value={this.state.prefix_name}
                        onChange={(e) => this.setState({prefix_name: e.value})}
                    />

                </div>
                
                <div className="k-form-buttons" style={{paddingRight: 40}}>
                    <Button
                        primary={true}
                        icon={'save'}
                    >
                        ثبت
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ priceApp }) => {
    return {
        //priceApp
    };
};

export default connect(
    mapStateToProps,
    {
        //getProductsList
    }
)(Database_new);