import {
    PRODUCT_GET_LIST,
    PRODUCT_GET_LIST_SUCCESS,
    PRODUCT_GET_GRID,
    PRODUCT_SET_GRID,
    PRODUCT_SET_SOCKET_UPDATE,
    CATEGORY_GET_LIST_SUCCESS,
    CATEGORY_GET_LIST
} from '../actionTypes';

const INIT_STATE = {
    allProducts: [],
    allCategories: [],
    gridProducts: [],
    error: false,
    loading: false,
    ploading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PRODUCT_GET_LIST:
            return { ...state, ploading: true, error: false };

        case PRODUCT_GET_LIST_SUCCESS:
            return { ...state, allProducts:action.payload, ploading: false, error: false };

        case PRODUCT_GET_GRID:
            const selectedGroup = action.payload;
            let tt = [];
            var ii=1;
            state.allProducts.forEach(item => {
                if(item.id_category === selectedGroup.id_category) {
                    
                    //item.push('id_row:+ii+');
                    item.id_row = ii;
                    tt.push(item);
                    //console.log(item);
                    ii++;
                }
            });
            return { ...state, gridProducts:tt, ploading: false, error: false };

        case PRODUCT_SET_GRID:
            return { ...state, gridProducts: action.payload };

        case PRODUCT_SET_SOCKET_UPDATE:
            return { ...state, allProducts: action.payload };

        case CATEGORY_GET_LIST:
            return { ...state, loading: true, error: false };

        case CATEGORY_GET_LIST_SUCCESS:
            return { ...state, allCategories:action.payload, loading: false, error: false };

        default: return { ...state };
    }
}
