import {
    DELIVERY_LIST,
    DELIVERY_LIST_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
    allDeliveries: [],
    error: false,
    loading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case DELIVERY_LIST:
            return { ...state, loading: true, error: false };

        case DELIVERY_LIST_SUCCESS:
            return { ...state, allDeliveries:action.payload, loading: false, error: false };

        default: return { ...state };
    }
}
