import React, {Component} from 'react';
import "./Login.css";
import { Redirect } from "react-router-dom";
import {connect} from "react-redux";
import {getUserCredential} from "../../data/auth/actions";
import {Form, Field, FormElement} from '@progress/kendo-react-form';
import {Input} from "@progress/kendo-react-inputs";
import Logo from "../../assets/logo.jpg";
import {requiredValidator} from '../../components/validator';

class Login extends Component{
    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null
        }
    }

    handleSubmit = (dataItem) => {
        localStorage.setItem('username', dataItem['username']);
        localStorage.setItem('password', dataItem['password']);
        this.props.getUserCredential();
    };


    render() {
        const { allowed, error } = this.props.authApp;
        
        if(allowed) {
            return <Redirect to="/" />
        } else {
            return (
                <div className="loginContainer">
                    <div className="k-rtl loginWrapper">
                        <div className="logo">
                            <img src={Logo} width={40} />
                        </div>
                        <Form
                            onSubmit={this.handleSubmit}
                            render={() => (
                                <FormElement style={{maxWidth: 650}}>
                                    <fieldset className={'k-form-fieldset'}>
                                        <legend className={'k-form-legend'}>ورود به سامانه CRM :</legend>

                                        <div className="mb-3">
                                            <Field
                                                name={'username'}
                                                component={Input}
                                                label={'نام کاربری'}
                                                validator={requiredValidator}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Field
                                                name={"password"}
                                                type={"password"}
                                                component={Input}
                                                label={"کلمه عبور"}
                                                validator={requiredValidator}
                                            />
                                        </div>
                                    </fieldset>
                                    <div className="k-form-buttons">
                                        <button
                                            type={'submit'}
                                            className="k-button"
                                        >
                                            ورود
                                        </button>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = ({ authApp }) => {
    return {
        authApp
    };
};

export default connect(
    mapStateToProps,
    {
        getUserCredential
    }
)(Login);