
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    DELIVERY_LIST
} from '../actionTypes';

import {
    getDeliveryListSuccess
} from '../delivery/actions';
import axios from "axios";
import { apiUrl } from "../../config/AppConfig";

const url = apiUrl;

const getDeliveriesRequest = async () => {
    return axios.get(url+'/api/delivery', {
        cache: true,
        auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
        }
    })
};

function* checkDeliveryList() {
    try {
        const res = yield call(getDeliveriesRequest);

        yield put(getDeliveryListSuccess(res.data));
    } catch (error) {
        // yield put(accessUserFails());
    }
}

export function* watchDeliveryList() {
    yield takeEvery(DELIVERY_LIST, checkDeliveryList);
}

export default function* rootSaga() {
    yield all([
        fork(watchDeliveryList)
    ]);
}