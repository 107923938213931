import React, {Component} from 'react';
import ReactToPrint from "react-to-print";
import ComponentToPrint2 from './print_out';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
//import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import {requiredValidator} from '../../components/validator';
import { AutoComplete } from '@progress/kendo-react-dropdowns';

//import TextField from '@mui/material/TextField';
//import Autocomplete from '@mui/material/Autocomplete';

import { filterBy } from '@progress/kendo-data-query';
import {getProductsList} from "../../data/priceList/actions";
import { ComboBox } from '@progress/kendo-react-dropdowns';

import Echo from 'laravel-echo';
import ListItem from '@progress/kendo-react-dropdowns/dist/npm/common/ListItem';

class AddInvoice extends Component{
    constructor(props) {
        super(props);

        this.state = {
            avvarez: '',
            tax: '',
            takhfif: '',
            customer: '',
            addr: '',
            invoiceDate: '',
            descriptionTotal:'',
            priceVahed:0,
            select_product:[{id_product:0,index:0,price:0}],
            autoCompleteValue: [{key: 0, value: '', price: 0, id: 0, id_attribute: 0}],
            autoCompleteData: [],
            productNumbers: [{key:0, value:''}],
            productfierstPrice: [{key:0, value:''}],
            productDescriptions: [{key:0, value:''}],
            productFinalPrice: [{key:0, value:''}],
            productTakhfif: [{key:0, value:'0'}],
        };
    }

    componentDidMount() {
        this.props.getProductsList();
    }

    handleSubmit = (dataItem) => {
         this.props.getUserCredential();
    };

    auto_on_select = (event,key) => {
        const value = event.target.value;
        let selected = {value: value, id:0, price:0, id_attribute:0};

        if(event.target.props.data[0] ) {
            let selData = event.target.props.data[0];
            selData = event.target.props.data.filter(item => item.legend === value )
            selData = selData[0];
            if(selData){
            selected = {value: value, id:selData.id_product, price:selData.price, id_attribute:selData.id_product_attribute};

            let pnums2 = this.state.productfierstPrice.map(itm => itm.key === key ? {...itm, value: selData.price} : itm);
            this.setState({productfierstPrice: pnums2});
            }
        }
        
        let prds = this.state.autoCompleteValue.map(item => item.key === key ? {...item, ...selected} : item);
        this.setState({
            autoCompleteValue: prds
        });    

        // let ptakhfif = {ke:key,value: '0'};
        // this.setState({productNumbers: ptakhfif})  
        //console.log(event); 

        let pnums = this.state.productNumbers.map(itm => itm.key === key ? {...itm, value: 0} : itm);
        this.setState({productNumbers: pnums});

        //console.log(event); 

    }

    autoCompleteChange = (event, key) => {
        let data = this.props.priceApp.allProducts;
        const value = event.target.value;
        let selected = {value: value, id:0, price:0, id_attribute:0};

        if(event.target.props.data[0] ) {
            let selData = event.target.props.data[0];
            selected = {value: value, id:selData.id_product, price:selData.price, id_attribute:selData.id_product_attribute};
        }

        let prds = this.state.autoCompleteValue.map(item => item.key === key ? {...item, ...selected} : item);
        this.setState({
            autoCompleteValue: prds
        });

        if(value.length > 2) {
            this.setState({
                autoCompleteData: data.filter(item => item.legend.includes(value))
            });
        }
        //console.log(event);

    };
    
    click_shode(event){
        //console.log("test");
        //console.log(event.target);
    }
    set_select_prod = (item,str) => {
        let id = {id_product:item.dataItem.id_product,index:item.index,price:item.dataItem.price}
        //this.setState({select_product: id});
        //console.log(id);
        //console.log(str);
    };
    getProductNumberValue = (i) => {
        let val = this.state.productNumbers.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };
    
    getProductfierstPrice = (i) => {
        let val = this.state.productfierstPrice.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };

    getProductDescriptionValue = (i) => {
        let val = this.state.productDescriptions.filter(item => item.key === i);
        return val[0] ? val[0].value : '';
    };
    productTakhfif = (i) => {
        let val = this.state.productTakhfif.filter(item => item.key === i);
        return val[0] ? val[0].value : 0;
    };
    getProductFinalPrice = (i) => {
        //console.log('final price : ');
        let val = this.state.productFinalPrice.filter(item => item.key === i);
        //console.log(val);

        return val[0] ? val[0].value : '0';
    };

    getProductValue = (i) => {
        let val = this.state.autoCompleteValue.filter(item => item.key === i);
        //console.log(val);
        //let x='';
        //if(this.state.autoCompleteData[0])
        //x=(this.state.autoCompleteData[0].id_product);
        return val[0] ? (val[0].value) : '';
    };

    addRow = (e) => {
        let data = [...this.state.productNumbers, {key:this.state.productNumbers.length, value: ''}];
        let datap = [...this.state.productfierstPrice, {key:this.state.productfierstPrice.length, value: ''}];
        let bdata = [...this.state.productDescriptions, {key:this.state.productDescriptions.length, value: ''}];
        let cdata = [...this.state.productFinalPrice, {key:this.state.productFinalPrice.length, value: 0}];
        let ddata = [...this.state.productTakhfif, {key:this.state.productTakhfif.length, value: 0}];
        let adata = [...this.state.autoCompleteValue, {key:this.state.autoCompleteValue.length, value: '', price: 0, id: 0,id_attribute: 0}];
        //console.log(data);
        //console.log(adata);
        //console.log(bdata);
        //console.log(this.state.productNumbers);

        this.setState({productfierstPrice: datap,productNumbers: data,productDescriptions: bdata,productFinalPrice: cdata,productTakhfif: ddata, autoCompleteValue: adata});
    };
    send_data = (e) => {
        let data = [...this.state.productNumbers];
        let bdata = [...this.state.productDescriptions];
        let adata = [...this.state.autoCompleteValue];
        //console.log(data.key);
        this.printPage();        
    };
  
get_out = () => {

        return (
            <div>
             <div
              className="pass-title"
              style={{ width: '100%', height: window.innerHeight }}
             >
              <h1 className='visitor-pass' style={{textAlign:'center'}} >Visitor Pass</h1>
              <table
                              style={{
                                  width: '50%',
                                  height: '50%',
                                  border: '1px solid black',
                                  margin: 'auto',
                                  fontSize: '30px',
                                  textAlign: 'center',
                              }}
                          >
                              <tr>
                                  <td>Name</td>
                                  <td>Rohan Kumar</td>
                              </tr>
                              <tr>
                                  <td>Phone no.</td>
                                  <td>9898989898</td>
                              </tr>
                              <tr>
                                  <td>Date of visit</td>
                                  <td>12-05-2023</td>
                              </tr>
                              <tr>
                                  <td>In Time</td>
                                  <td>11:00</td>
                              </tr>
                          </table>
             </div>
             
            </div>
           );

    };

    printPage=(e)=> {
 
        // اون باکسی که باید پرینت گرفته شه
        var mycontent = 'aaa';//document.getElementById("mybox").innerHTML;
         
        // فراخوانی هد صفحه فعلی در صفحه پرینت
        var head = 'bbb';//document.getElementsByTagName("head")[0].innerHTML;
         
        // فراخوانی عنوان صفحه
        var mytitle = 'ccc';//document.getElementsByTagName("title")[0].innerHTML;
         
        // ایجاد یک صفحه / پنجره جدید
        var myWindow = window.open('','','');
         
        var text3=this.get_out();
        console.log(text3);

        // ریختن محتوایی که باید چاپ شه در صفحه چاپ
        myWindow.document.write("<html><head>"+<this.get_out/>+"</body></html>");
         
        // فراخوانی پنجره پرینت
        myWindow.print();
        //myWindow.close();
    };
    
    removeItem (array_,key) {
        let list = array_.filter(item => item.key !== key);
        for (var i = key; i < list.length; i++) {
            list[i].key = list[i].key - 1;
        }
        return list;
    }

    removeRow = (key) => {
        if(this.state.productNumbers.length > 1) {
            
            // let data  = this.state.productNumbers.filter(item => item.key !== key);
            // let cdata = this.state.productFinalPrice.filter(item => item.key !== key);
            // let ddata = this.state.productDescriptions.filter(item => item.key !== key);
            // let fdata = this.state.productTakhfif.filter(item => item.key !== key);
            // let adata = this.state.autoCompleteValue.filter(item => item.key !== key);
            
            // let data = this.state.productNumbers.splice(key,1);
            // let cdata = this.state.productFinalPrice.splice(key,1);
            // let ddata = this.state.productDescriptions.splice(key,1);
            // let fdata = this.state.productTakhfif.splice(key,1);
            // let adata = this.state.autoCompleteValue.splice(key,1);

            let cdata = this.removeItem(this.state.productFinalPrice,key);
            let ddata = this.removeItem(this.state.productDescriptions,key);
            let fdata = this.removeItem(this.state.productTakhfif,key);
            let adata = this.removeItem(this.state.autoCompleteValue,key);
            let data =  this.removeItem(this.state.productNumbers,key);
            let datap =  this.removeItem(this.state.productfierstPrice,key);
            
            //console.log(data);
            //console.log(cdata);
            this.setState({productfierstPrice: datap ,productNumbers: data ,productTakhfif: fdata,productFinalPrice: cdata,productDescriptions: ddata, autoCompleteValue: adata});
            //this.setState({productNumbers: this.state.productNumbers ,productTakhfif: this.state.productTakhfif,productFinalPrice: this.state.productFinalPrice,productDescriptions: this.state.productDescriptions, autoCompleteValue: this.state.autoCompleteValue});
            //console.log(this.state.productNumbers);
        }
    };
    resetFormFields = () => {
        this.setState({customer:""});
        this.setState({addr:""});
        this.setState({tax:""});
        this.setState({takhfif:""});
        this.setState({avvarez:""});
        this.setState({invoiceDate:""});
        this.setState({priceVahed:"0"});
        this.setState({descriptionTotal:""});
        this.setState({productNumbers: [{key:0, value:''}]});
        this.setState({productfierstPrice: [{key:0, value:''}]});
        this.setState({productDescriptions: [{key:0, value:''}]});
        this.setState({productFinalPrice: [{key:0, value:''}]});
        this.setState({productTakhfif: [{key:0, value:''}]});
        this.setState({autoCompleteValue: [{key: 0, value: '', price: 0, id: 0, id_attribute: 0}]});
        this.setState({autoCompleteData: []});

        // autoCompleteValue: [{key: 0, value: '', price: 0, id: 0, id_attribute: 0}],
        // autoCompleteData: [],
        // productDescriptions: [{key:0, value:''}],
        // productFinalPrice: [{key:0, value:''}],
        // productTakhfif: [{key:0, value:'0'}],
    }
    getformat = (i) => {
        let nf = new Intl.NumberFormat('en-US')
        return nf.format(i); // "1,234,567,890" 
    };

    // filterChange = (event) => {

    //     if( event.filter.value == '' ){
    //         console.log(' return null = ',event.filter.value)
    //         return;
    //     }
    //     this.setState({
    //         autoCompleteValue: this.filterData(event.filter)
    //     });
    // };

    // filterData(filter) {

    //     const data = this.props.supplierApp.allSuppliers.slice();
    //     return filterBy(data, filter);
    // }

    render() {
        const { allProducts, loading } = this.props.priceApp;

        return (

            <div className="k-rtl App" style={{ marginTop: '10px'}}>
                <div className="reset-button" style={{width:'10%',marginRight:'auto',marginLeft:'auto',flexDirection:'column-reverse',marginTop:'15px',marginBottom:'15px'}}>
                    <Button
                        onClick={this.resetFormFields}
                        primary={true}                        
                    >
                     ایجاد فاکتور جدید
                    </Button>
                </div>
                <div style={{backgroundColor:'whitesmoke',boxShadow:'5px 5px 15px 5px rgb(226 226 226)',marginLeft:'auto',marginRight:'auto',padding:'1%'}}>
                <   div style={{marginBottom:'10px',marginTop:'15px'}}>                    
                        <Label editorId='descriptionTotal' className='formLabel'>
                            توضیحات فاکتور:
                        </Label>
                        <Input
                            id='descriptionTotal'
                            value={this.state.descriptionTotal}
                            onChange={(e) => this.setState({descriptionTotal: e.value})}
                            style={{width:'47.5%'}}
                        />
                    </div>

                    <div className="k-flex-row formRow" style={{marginBottom:'10px',marginTop:'15px'}}>
                        <Label editorId='customer' className='formLabel'>
                            نام خریدار:
                        </Label>
                        <Input
                            id='customer'
                            value={this.state.customer}
                            onChange={(e) => this.setState({customer: e.value})}
                        />
                        <Label editorId='addr' className='formLabel'>
                            آدرس خریدار:
                        </Label>
                        <Input
                            id='addr'
                            value={this.state.addr}
                            onChange={(e) => this.setState({addr: e.value})}
                            style={{width:'28%'}}
                        />
                    </div>
                    <div className="k-flex-row formRow">
                        <Label editorId='invoiceDate' className='formLabel'>
                            تاریخ:
                        </Label>
                        <DatePicker
                            //defaultValue={('1')}
                            id='invoiceDate'
                            format="HH:mm YYYY/MM/DD"
                            // format="y/M/d"
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                            value={this.state.invoiceDate}
                            onChange={(e) => this.setState({invoiceDate : e.toString()
                                            //console.log(this.state.invoiceDate);
                                            })
                                    }
                        />

                        <Label editorId='takhfif' className='formLabel' >
                            تخفیف:
                        </Label>
                        <Input
                            id='takhfif'
                            value={this.state.takhfif}
                            onChange={(e) => this.setState({takhfif: e.value})}
                            style={{width: '60px'}}
                        />
                        
                        <Label editorId='tax' className='formLabel'>
                            مالیات:
                        </Label>
                        <Input
                            id='tax'
                            value={this.state.tax}
                            onChange={(e) => this.setState({tax: e.value})}
                            style={{width: '60px'}}
                        />
                        
                        <Label editorId='avvarez' className='formLabel'>
                            عوارض:
                        </Label>
                        <Input
                            id='avvarez'
                            value={this.state.avvarez}
                            onChange={(e) => this.setState({avvarez: e.value})}
                            style={{width: '60px'}}
                        />
                    </div>
                </div>
                <div className="k-flex-row formRow k-d-flex k-align-items-center" style={{backgroundColor:'whitesmoke',boxShadow:'5px 5px 15px 5px rgb(226 226 226)',marginTop:'3%',marginLeft:'auto',marginRight:'auto',padding:'1%'}}> 
                  <div style={{marginBottom:'10px',marginTop:'15px',marginLeft:'auto',marginRight:'auto'}}>  
                    
                    { loading ? <div className="loading"></div> :
                    <table className="addInvoiceTable" >
                        <thead>
                            <tr>
                                <th>محصول</th>
                                <th>تعداد</th>
                                <th>بهای واحد</th>
                                <th>تخفیف</th>
                                <th>بهای نهایی</th>
                                <th>مبلغ کل</th>
                                <th>شرح کالا</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.productNumbers.map(item =>
                            <tr key={item.key}>
                                <td>                                         
                                    {/* <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={this.state.autoCompleteData}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Movie" />}
                                    /> */}
                                    <AutoComplete
                                        data={this.state.autoCompleteData}
                                        value={this.getProductValue(item.key)}
                                        onChange={(e) => {this.autoCompleteChange(e, item.key);
                                        }}
                                        //onFocus={(e)=>{console.log("xxx")}}
                                        //onBlur={(e)=>{console.log("yyy")}}
                                        onClose={(e)=>{this.auto_on_select(e, item.key)}}
                                        // onInputChange={(e) => {this.autoCompleteChange(e, item.key);
                                        // }}
                                        
                                        itemRender = { (li, itemProps) => {
                                            const index = itemProps.dataItem.legend + '|' + itemProps.dataItem.combinationName;
                                            const itemChildren = <span>{index}</span>;
                                        if( !itemProps.dataItem.legend.includes(itemProps.dataItem.combinationName) && itemProps.dataItem.combinationName !== null )    
                                             itemProps.dataItem.legend = itemProps.dataItem.legend + '|' + itemProps.dataItem.combinationName;
                                        if(itemProps.focused){
                                            this.set_select_prod(itemProps,itemProps.dataItem.combinationName);
                                        }
                                        
                                        //itemProps.onClick={::this.click_shode}
                                             //this.set_select_prod(itemProps);
                                        
                                        //console.log(itemProps.dataItem.id_product);
                                            //this.autoCompleteChange(e, itemProps.dataItem.id_product);
                                            return React.cloneElement(li, li.props, itemChildren);
                                        }}
                                        
                                        textField={('legend') }
                                        dataItemKey={'named'}
                                        style={{width: '300px'}}
                                        // id={e.target}
                                        
                                    />
                                    
                                </td>
                                <td>
                                    <Input value={this.getProductNumberValue(item.key)}
                                    onChange={(e) => {
                                        let pnums = this.state.productNumbers.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                        this.setState({productNumbers: pnums});
                                        let takh =this.getProductfierstPrice(item.key) -
                                                    this.state.productTakhfif.filter(itm => itm.key === item.key)[0].value;
                                        let ptakhfif = this.state.productFinalPrice.map(itm => itm.key === item.key ? {...itm, value: takh} : itm);
                                        this.setState({productFinalPrice: ptakhfif})
                                    }}
                                    style={{width: '50px'}}
                                    />
                                </td>

                                <td > 
                                     {/* { this.state.autoCompleteValue.filter(itm => itm.key === item.key)[0].price } */}
                                     
                                     <Input value={this.getProductfierstPrice(item.key)}
                                        onChange={(e) => {
                                            let pnums = this.state.productfierstPrice.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                            this.setState({productfierstPrice: pnums});

                                            let takh = e.value - this.productTakhfif(item.key);
                                            let ptakhfif = this.state.productFinalPrice.map(itm => itm.key === item.key ? {...itm, value: takh} : itm);
                                            this.setState({productFinalPrice: ptakhfif})
                                     }}
                                        style={{textAlign: 'center', width: 100}}
                                    />
                                 </td>
                                 
                                 
                                 {/* <td style={{textAlign: 'center', width: 100}}> 
                                     { this.state.autoCompleteValue.filter(itm => itm.key === item.key)[0].price }
                                 </td> */}

                                <td >
                                    <Input 
                                    value={this.productTakhfif(item.key) }//this.getProductFinalPrice(item.key)}
                                    onChange={(e) => {
                                            let pnums = this.state.productTakhfif.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                            this.setState({productTakhfif: pnums})
                                            let takh =this.getProductfierstPrice(item.key) - e.value;
                                            let ptakhfif = this.state.productFinalPrice.map(itm => itm.key === item.key ? {...itm, value: takh} : itm);
                                            //console.log(pnums);
                                            //console.log(ptakhfif);
                                            //console.log(item);
                                            this.setState({productFinalPrice: ptakhfif})
                                            //console.log('final 1 : ')
                                            //console.log(this.state.productFinalPrice)
                                            }}
                                     style={{width: '70px'}}       
                                    />
                                    {/* { this.state.autoCompleteValue.filter(itm => itm.key === item.key)[0].price } */}
                                </td>

                                <td style={{textAlign: 'center', width: 100}}>
                                   { this.getProductfierstPrice(item.key) -
                                     this.state.productTakhfif.filter(itm => itm.key === item.key)[0].value }
                                    {/* {this.setState({productFinalPrice: pnums})} */}
                                    {/* {this.getProductFinalPrice(item.key)} */}
                                </td>

                                <td style={{textAlign: 'center', width: 100}}>
                                    { this.getProductFinalPrice(item.key) *
                                      this.state.productNumbers.filter(itm => itm.key === item.key)[0].value 
                                    }
                                </td>
                                <td>
                                    <Input  value={this.getProductDescriptionValue(item.key)}
                                           onChange={(e) => {
                                               let pnums = this.state.productDescriptions.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                               this.setState({productDescriptions: pnums});
                                           }}
                                    />
                                    {/* {console.log(this.state.productDescriptions)} */}
                                </td>
                                <td><Button style={{backgroundColor:'red',borderRadius:'3px',marginRight:'5px'}} onClick={(_e) => this.removeRow(item.key)}><i className='lni lni-minus lni-16 fa fa-minus' style={{fontSize:'10px'}} /></Button></td>
                            </tr>)
                        }
                        <tr>
                            <td ><Button style={{backgroundColor:'green',marginTop:'5px'}} onClick={this.addRow}><i className='lni lni-plus lni-16 fa fa-plus' style={{fontSize:'15px'}} /></Button></td>
                        </tr>
                        </tbody>
                    </table> }
                    </div>
                  </div>
                <div className="k-form-buttons" style={{width:'55%',marginRight:'auto',marginLeft:'auto',flexDirection:'column-reverse',paddingRight: 40,marginTop:'15px'}}>
                {/* <Button
                    //onClick={this.send_data}
                    onClick={this.resetFormFields}
                    //onClick={new ComponentToPrint10().chnege_visibility()}
                    primary={true}
                    icon={'save'}
                    
                >
                    ثبت
                </Button> */}
                <ReactToPrint
                    trigger={() => <Button
                                        //onClick={()=> ComponentToPrint10.chnege_visibility()}
                                        onClick={'a'}
                                        primary={true}
                                        icon={'save'}
                                        style={{width:'15%',marginBottom:'6%',marginTop:'2%'}}

                                   >
                                    پرینت
                                   </Button>
                                   }
                    content={() => this.componentRef}
                    />
                <div style={{display:"flow",backgroundColor:'#fbfbfb',borderRadius:'6%',marginTop:'2%'}}>
                    <ComponentToPrint2 
                       takhfif={this.state.takhfif} 
                       takhfifvahed={this.state.productTakhfif} 
                       priceFinal={this.state.productFinalPrice} 
                       priceFinalfun={this.getProductFinalPrice} 
                       avarez={this.state.avvarez} 
                       tax={this.state.tax} 
                       autocomp={this.state.autoCompleteValue} 
                       pro={this.state.productNumbers} 
                       coust={this.state.customer} 
                       address={this.state.addr} 
                       descTotal={this.state.descriptionTotal} 
                       datee={this.state.invoiceDate} 
                       desc={this.state.productDescriptions} 
                       ref={el => (this.componentRef = el)} />
                </div>    
                
                    
                </div>
            </div>
        );
    }
}
//<a href="#">Print this out!</a>
const mapStateToProps = ({ priceApp }) => {
    return {
        priceApp
    };
};


export default connect(
    mapStateToProps,
    {
        getProductsList
    }
)(AddInvoice);