import React, {Component} from 'react';
import { Redirect } from "react-router-dom";
import {connect} from "react-redux";
import {userLogout} from "../../data/auth/actions";

class Logout extends Component{

    componentDidMount() {
        this.props.userLogout();
    }

    render() {
        return <Redirect to='login' />
    }
}

const mapStateToProps = ({ authApp }) => {
    return {
        authApp
    };
};

export default connect(
    mapStateToProps,
    {
        userLogout
    }
)(Logout);