import React, { Component, Fragment } from "react";
import {connect} from "react-redux";
import {getDeliveryList} from "../../data/delivery/actions";
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import '@progress/kendo-theme-default/dist/all.css';
import { apiUrl } from '../../config/AppConfig';
import messages from '../../lang/kendo-fa-IR';
import SupplierDetail from "../pricelist/supplierDetail";


class DeliveryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: null,
            city: "",
            address: "",
            customer: "",
            taker: "",
            sendDate: "",
            size: "",
            referral: "",
            description: "",

            editMode: false,
            gridDataState: {},
            deliveries: []
        };

        const language = 'fa';
        loadMessages(messages, language);
    }

    render() {
        let {allDeliveries} = this.props.deliveryApp;
        return (
            <div className="row k-rtl">
                {this.state.loading ? <div className="loading"></div> :
                    <div style={{padding: 20}}>
                        <h3> ارسال ها
                        </h3>
                        {this.state.error ? <span className="errorMsg">{this.state.error}</span> : ""}
                        <Card className="k-rtl"
                              style={{boxShadow: '0 0 4px 0 rgba(0, 0, 0, .1)', marginTop: '15px', marginBottom: 10}}>
                            <CardHeader className="k-hbox" style={{background: 'transparent'}}>
                                <CardTitle style={{marginBottom: '4px'}}> افزودن </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="vertical-form">
                                    <div className="form-group">
                                        <Label> سفارش: </Label>
                                        <div style={{marginRight: 10}}>
                                            <Input type="text" className="form-control" onChange={(e) => {
                                                this.setState({order: e.target.value})
                                            }}
                                                   value={this.state.order}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <Label> مشتری: </Label>
                                        <div style={{marginRight: 10}}>
                                            <Input type="text" className="form-control" onChange={(e) => {
                                                this.setState({customer: e.target.value})
                                            }}
                                                   value={this.state.customer}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <Label> شهر: </Label>
                                        <div style={{marginRight: 10}}>
                                            <Input type="text" className="form-control" onChange={(e) => {
                                                this.setState({city: e.target.value})
                                            }}
                                                   value={this.state.city}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <Label> آدرس: </Label>
                                        <div style={{marginRight: 10}}>
                                            <Input type="text" className="form-control" onChange={(e) => {
                                                this.setState({address: e.target.value})
                                            }}
                                                   value={this.state.address}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <Label> توضیحات: </Label>
                                        <div style={{marginRight: 10}}>
                                            <Input rows={2} className="form-control" onChange={(e) => {
                                                this.setState({description: e.target.value})
                                            }}
                                                   value={this.state.description}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button icon="save" style={{marginRight: 10}}
                                        onClick={this.addItem}>{this.state.editMode ? 'ویرایش' : 'افزودن'}
                                </Button>
                            </CardBody>
                        </Card>

                        <LocalizationProvider language="fa">
                            <div dir="rtl" className="k-rtl my-3" style={{height: '75vh', overflow: 'scroll'}}>
                                <Grid
                                    data={process(allDeliveries, this.state.gridDataState)}
                                    style={{maxHeight: '100%', minWidth: '900px', fontSize: '1.2em'}}
                                    filterable={true}
                                    sortable={false}
                                    pageable={false}
                                    expandField="expanded"
                                    detail={SupplierDetail}
                                    {...this.state.gridDataState}
                                    onDataStateChange={(e) => {
                                        this.setState({gridDataState: e.data})
                                    }}
                                    onExpandChange={
                                        event => {
                                            event.dataItem.expanded = !event.dataItem.expanded;
                                            this.forceUpdate();
                                        }
                                    }
                                    resizable
                                >
                                    <GridColumn field="id" title="شناسه" filter="numeric" width={120}/>
                                    <GridColumn field="supplier_title" title="عنوان"/>
                                    <GridColumn field="tell" title="تلفن"/>
                                    <GridColumn field="mobile" title="موبایل"/>
                                    <GridColumn field="card" title="شماره کارت"/>
                                    <GridColumn field="website" title="وب سایت"/>
                                    <GridColumn title="عملیات" filterable={false} width={200} cell={(props) => {
                                        return (
                                            <td className="k-d-flex k-align-items-center">
                                                <Button className="btn btn-info btn-sm m-1">
                                                <span className="text-light" onClick={() => {
                                                    this.setState({
                                                        editMode: true,
                                                        editId: props.dataItem.id,
                                                        title: props.dataItem.supplier_title,
                                                        description: props.dataItem.description == null ? '' : props.dataItem.description,
                                                        address: props.dataItem.address == null ? '' : props.dataItem.address,
                                                        tell: props.dataItem.tell == null ? '' : props.dataItem.tell,
                                                        mobile: props.dataItem.mobile == null ? '' : props.dataItem.mobile,
                                                        card: props.dataItem.card == null ? '' : props.dataItem.card,
                                                        website: props.dataItem.website == null ? '' : props.dataItem.website,
                                                    });
                                                }}>
                                                    ویرایش
                                                </span>
                                                </Button>
                                                <Button style={{marginRight: 10}}
                                                        onClick={() => this.deleteItem(props.dataItem.id)}
                                                >
                                                <span className="text-light">
                                                    حذف
                                                </span>
                                                </Button>
                                            </td>
                                        )
                                    }}/>
                                </Grid>
                            </div>
                        </LocalizationProvider>
                    </div>}
            </div>);
    }
}

const mapStateToProps = ({ deliveryApp }) => {
    return {
        deliveryApp
    };
};

export default connect(
    mapStateToProps,
    {
        getDeliveryList
    }
)(DeliveryList);