import {
    DELIVERY_LIST,
    DELIVERY_LIST_SUCCESS,
} from '../actionTypes';

export const getDeliveryList = () => ({
    type: DELIVERY_LIST
});

export const getDeliveryListSuccess = (items) => ({
    type: DELIVERY_LIST_SUCCESS,
    payload: items
});