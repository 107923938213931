import { combineReducers } from 'redux';
import priceApp from './priceList/reducer';
import authApp from './auth/reducer';
import supplierApp from "./supplier/reducer";
import deliveryApp from "./delivery/reducer";

const reducers = combineReducers({
    priceApp,
    authApp,
    supplierApp,
    deliveryApp
});

export default reducers;