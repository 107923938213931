
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    AUTH_GET_CREDENTIAL
} from '../actionTypes';

import {
    getUserCredentialSuccess,
    getUserCredentialError
} from '../auth/actions';
import axios from "axios";
import {apiUrl} from "../../config/AppConfig";

const url = apiUrl;

const getAuthRequest = async () => {
    return axios.post(url+'/api/auth', {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('password')
    })
};

function* checkUserAuth() {
    try {
        const res = yield call(getAuthRequest);
        if(res.data.allowed) {
            yield put(getUserCredentialSuccess(res.data.msg));
        } else {
            yield put(getUserCredentialError(res.data.msg));
        }
    } catch (error) {
        yield put(getUserCredentialError('error'));
    }
}

export function* watchUserAuth() {
    yield takeEvery(AUTH_GET_CREDENTIAL, checkUserAuth);
}

export default function* rootSaga() {
    yield all([
        fork(watchUserAuth)
    ]);
}