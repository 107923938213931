import React, { Fragment } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Prices from "./pricelist/prices";
import AddInvoice from "./invoice/addInvoice";
import PricesHistory from "./pricelist/pricesHistory";
import Login from "./auth/login";
import Logout from "./auth/logout";
import Supplier from "./pricelist/supplier";
import Delivery from "./delivery";
import Database_new from "./database/database";
import MainHeader from '../components/mainHeader';
import '@progress/kendo-theme-default/dist/all.css';
import '../assets/App.css';
import {connect} from "react-redux";
import { getUserCredential } from "../data/auth/actions";
import PusherListener from "../components/pusherListener";
// import TestApp from "../routes/pricelist/test";

class Routes extends React.Component {

    componentWillMount() {
        this.props.getUserCredential();
    }

    render() {
        const { allowed, loading } = this.props.authApp;

        return (
            <Fragment>
                <PusherListener />
                {loading ? <div className="loading"/> :
                    <Router>
                        {allowed ? <MainHeader/> : ""}
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/logout" component={Logout}/>
                            {!allowed ?
                                <Redirect to="/login"/> : ""
                            }
                            <Route exact path="/" component={Prices}/>
                            <Route path="/invoice" component={AddInvoice}/>
                            <Route path="/history" component={PricesHistory}/>
                            <Route path="/supplier" component={Supplier}/>
                            {/* <Route path="/price-list/supplier" component={Supplier}/> */}
                            <Route path="/delivery" component={Delivery}/>
                            <Route path="/database" component={Database_new}/>
                            {/*<Route path="/test" component={TestApp}/>*/}
                        </Switch>
                    </Router>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = ({ authApp }) => {
    return {
        authApp
    };
};

export default connect(
    mapStateToProps,
    {
        getUserCredential
    }
)(Routes);