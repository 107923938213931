import React, {useRef} from 'react';
import {ReactToPrint} from "react-to-print";
import PropTypes from "prop-types";
import Logo from "../../assets/logo.jpg";
import "./print.css";

import {connect} from "react-redux";
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import {requiredValidator} from '../../components/validator';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import {getProductsList} from "../../data/priceList/actions";
import { findAllByAltText } from '@testing-library/react';

export default class ComponentToPrint10 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        facNum:0,
        TotalNum:0,
        gridDataState: {},
        inEditField: null,
        editedField: null,
        loading: false,
        updateLoading: false,
        updateErr: false,
        catDisplay: true,
        searchMsg: false,
        modalVisible: true,
        modalProduct: null,
        selectedSupplier: false,
        supplierData: [],
        supplier: false,
        totalSearchValue: '',
    };
  }


getProductNumberValue = (i) => {
    let val = this.props.pro.filter(item => item.key === i);
    return val[0] ? val[0].value : '';
};

getProductDescriptionValue = (i) => {
    let val = this.props.desc.filter(item => item.key === i);
    return val[0] ? val[0].value : '';
};

getProductValue = (i) => {
    let val = this.props.autocomp.filter(item => item.key === i);
    return val[0] ? val[0].value : '';
};

getformat = (i) => {
    let nf = new Intl.NumberFormat('en-US')
    return nf.format(i); // "1,234,567,890" 
};

getTotalNum = () => {
    var total=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.props.pro[i].value)+parseFloat(total);
    }

    // console.log(this.props.pro[0].value);
    // console.log(this.props.pro.length);
    // console.log(this.props.pro);
    // console.log(total);

    return total; 
};

getTotalPrice = () => {
    var total=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.getProductNumberValue(i))*parseFloat(this.props.priceFinalfun(i))+parseFloat(total);
    }

    //console.log(this.props.autocomp[0].price);
    //console.log(this.props.autocomp.length);
    //console.log(this.props.autocomp);
    //console.log(total);

    return total; 
};

payPrice = () => {
    var total=0;
    var total_org=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.getProductNumberValue(i))*parseFloat(this.props.priceFinalfun(i))+parseFloat(total);
    }
    total_org = total;
    if( this.props.takhfif.length >0 )
       total =  total - ((total_org*parseFloat(this.props.takhfif))/100)
    
    if( this.props.tax.length >0 )
       total =  total + ((total_org*parseFloat(this.props.tax))/100)

    if( this.props.avarez.length >0 )
       total =  total + ((total_org*parseFloat(this.props.avarez))/100)

    return total; 
};

TakhfifPrice = () => {
    var total=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.getProductNumberValue(i))*parseFloat(this.props.priceFinalfun(i))+parseFloat(total);
    }

    if( this.props.takhfif.length >0 )
       total = ((total*parseFloat(this.props.takhfif))/100)
    else total =0;

    return total; 
};

TaxPrice = () => {
    var total=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.getProductNumberValue(i))*parseFloat(this.props.priceFinalfun(i))+parseFloat(total);
    }

    if( this.props.tax.length >0 )
       total = ((total*parseFloat(this.props.tax))/100)
    else total =0;

    return total; 
};
AvarezPrice = () => {
    var total=0;
    for (var i=0; i < this.props.pro.length; i++) {
        if(this.props.pro[i].value.length >0)
        total=parseFloat(this.getProductNumberValue(i))*parseFloat(this.props.priceFinalfun(i))+parseFloat(total);
    }

    if( this.props.avarez.length >0 )
       total = ((total*parseFloat(this.props.avarez))/100)
    else total =0;

    return total; 
};
getFactNum = () => {
    const dd=new Date();
    this.state.facNum=dd.getMonth().toString()+dd.getDate().toString()+dd.getHours().toString()+dd.getMinutes().toString()+dd.getSeconds().toString();
    return this.state.facNum; 
};

render() {
      const { modalVisible } = this.state;

      return (
        <div >
        <div className="container">
            <div className='header-crm'>
                <div>
                    <p className="logo">
                        <img src={Logo} width={80} />
                    </p>
                </div>
                <div style={{textAlign:"center"}}>
                    <h5>  پیش فاکتور </h5>
                    <h3> آساک سیستم </h3>
                </div>
                <div>
                        
                    <p>
                        <Label editorId='invoiceDate' className='Label-crm'>
                                        تاریخ :  
                        </Label>
                        <Label editorId='invoiceDate' className='Label-crm'>
                            {(this.props.datee)}
                        </Label>
                    </p>
                    <p>
                        <Label editorId='factnum' className='Label-crm'>
                                        شماره :  
                        </Label>
                        <Label editorId='factnum' className='Label-crm'>
                            {(this.getFactNum())}
                        </Label>
                    </p>
                </div>
            </div>
        <div className='adress-customer'> {'خریدار : '+this.props.coust +' - '+this.props.address} </div>
        <div className='div-table'>
        <table id="customers">
        <thead>
                            <tr>
                                <th style={{maxWidth:'12%'}}>ردیف</th>
                                <th>شرح</th>
                                <th>تعداد</th>
                                <th>تخفیف</th>
                                <th >بهای واحد</th>
                                <th>مبلغ کل</th>
                                <th>ملاحظات </th>
                            </tr>
                        </thead>

                        <tbody>
                        {this.props.pro.map(item =>
                            <tr key={item.key}>
                                <td>{item.key+1}</td>
                                <td>
                                    
                                    <Label>
                                        {this.getProductValue(item.key)}
                                        
                                    </Label>
                                </td>
                                <td>
                                    <Label>{this.getProductNumberValue(item.key)}</Label>
                                    {/* <Input value={this.getProductNumberValue(item.key)}
                                    onChange={(e) => {
                                        let pnums = this.state.productNumbers.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                        this.setState({productNumbers: pnums});
                                    }}
                                    style={{width: '50px'}}
                                    /> */}
                                </td>
                                <td style={{textAlign: 'center', maxWidth: 50}}>
                                   { this.getformat(this.props.takhfifvahed.filter(itm => itm.key === item.key)[0].value) }
                                </td>
                                
                                <td style={{textAlign: 'center', maxWidth: 80}}>
                                    {/* { this.getformat(this.props.priceFinal.filter(itm => itm.key === item.key)[0].value) } */}
                                    {this.getformat(this.props.priceFinalfun(item.key))}
                                </td>
                               
                                <td style={{textAlign: 'center', width: 100}} >
                                    { this.getformat(this.props.priceFinalfun(item.key) *
                                                     this.getProductNumberValue(item.key))}
                                </td>
                                
                                <td>
                                    <Label>{this.getProductDescriptionValue(item.key)}</Label>
                                    {/* <Input  value={this.getProductDescriptionValue(item.key)}
                                           onChange={(e) => {
                                               let pnums = this.state.productDescriptions.map(itm => itm.key === item.key ? {...itm, value: e.value} : itm);
                                               this.setState({productDescriptions: pnums});
                                           }}
                                    /> */}
                                </td>
                            </tr>
                            )
                        }
                        <tr></tr>
                        
                        <tr >
                        <th colSpan="2" style={{borderLeftColor:'#cdcece'}}>جمع فاکتور</th>
                        <th colSpan="1">{this.getTotalNum()}</th>
                        <th colSpan="1" style={{borderLeftColor:'#cdcece'}}>قیمت کل</th>
                        <th colSpan="3">{this.getformat(this.getTotalPrice())}</th>
                        </tr>
                        
                        <tr >
                        <td colSpan="3" rowSpan="3" style={{backgroundColor:'rgb(255 255 255)',borderBottomColor:'rgb(211 211 211)'}}>{this.props.descTotal}</td>
                        <td colSpan="1">تخفیف</td>
                        <td colSpan="3">({this.props.takhfif} %) {this.getformat(this.TakhfifPrice())}</td>
                        </tr>
                        
                        <tr >
                        {/* <td colSpan="3" style={{backgroundColor: 'rgb(255 255 255)',borderBottomColor:'rgb(255 255 255)'}}></td> */}
                        <td colSpan="1">مالیات</td>
                        <td colSpan="3">({this.props.tax} %) {this.getformat(this.TaxPrice())}</td>
                        </tr>
                        
                        <tr >
                        {/* <td colSpan="3" ></td> */}
                        <td colSpan="1">عوارض</td>
                        <td colSpan="3">({this.props.avarez} %) {this.getformat(this.AvarezPrice())}</td>
                        </tr>
                        
                        <tr >
                        <td colSpan="4" style={{fontStyle:'oblique'}}>مبلغ قابل پرداخت</td>
                        <td colSpan="3">{this.getformat(this.payPrice())}</td>
                        </tr>
                        </tbody>
                        
        </table>
        </div>

            <div className='adress'>نشانی : تهران - خ کریمخان - خ ایرانشهر - کوچه نوشهر - پ 32 - واحد 4</div>             
            <div className='tel'>تلفن : 09109787284</div>
            <div className='sign'>
                <div>امضاء خریدار</div>  
                <div>امضاء فروشنده</div>  
            </div>           
        </div>
        </div>
        );
    }
  }

